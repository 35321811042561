.App {
  text-align: center;

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600;700;800&display=swap');

@font-face {

  font-family: 'agrandir';
  src: url("./fonts/Agrandir-Regular.otf");
}




/* Custom, iPhone Retina */
@media only screen and (min-width : 110px) and (max-width:1100px) {


  .App{
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    grid-template-rows: repeat(14, 1fr);
  }

  html{

    margin: 0;
    padding: 0;
  }

  *{
    margin: 0;
    padding: 0;
  }
   
  
  
  ul.nav {

    display: none;
  
  
    grid-column: 3 / 12;
    justify-self: center;
    align-self: end;
  
  }
  
  ul.nav>li {
    display: inline-block;
    padding: 10px 20px;
    font-size: 20px;
  }
  
  ul.nav>li:hover {
    background-color: #8f2525;
  }
  
  ul.nav>li>a {
    color: white;
    text-decoration: none;
  }
  
  
  .App-link {
    color: #61dafb;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
  
    to {
      transform: rotate(360deg);
    }
  }
  
  
  * {
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
  }
  

  
  
  
  #header {
  
    font-size: 1rem;
    text-align: left;
    align-content: left;
    margin-left: 5vw;
    margin-top: 1vw;
  }
  
  
  .App-header {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: #800020;
  
    height: 8rem;
  
    display: grid;
  
    grid-template-columns: repeat(12, 1fr);
  
  
    color: white;

    z-index: 10;
  }
  


  
  
  #homepageHeaderMessage {
  
  
    grid-column: 3/12;
    font-size: 0.6rem;
    justify-self: center;
    align-self: center;
    position: relative;
  
  
  }
  
  #aboutMe {
    font-size: 0.7rem;
    grid-column: 10 / 13;
    text-align: right;  
    align-self: end;
    position: relative;
    right: 0.2rem;
    bottom: 0.2rem;
  
  }
  
  
  #homepageLogo {
  
    position: absolute;
    left: 0.5rem;
    align-self: center;
    grid-column: 1 / 9;
    width: 14rem;
    height: 14rem;
  
  }

 
  
  
  .pageHeader {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: #800020;
  
    height: 5.5rem;
  
    display: grid;
  
    grid-template-columns: repeat(13, 1fr);
    grid-template-rows: repeat(5, 1fr);
  
  
    color: white;
    z-index: 10;
  }
  
  .contactInfoPage {
  
    font-size: 0.5rem;
    grid-column: 9/14;
    grid-row: 1/2;
    text-align: right;
    position: relative;
    right: 0.5rem;
  
  }
  
  .pageLogo {

    position: absolute;
    grid-column: 1 / 7;
    grid-row: 1/ 6;
    align-self: center;
    height: 8.5rem;
    width: 8.5rem;
    left: 0.2rem;
    bottom: -1.5rem;
  
  
  }

  .projectContainer{

    position: relative;
    bottom: 0.4rem;
    right: 0.2rem;
    align-self: center;
    grid-column: 12/14;
    background-color: #389979;
    grid-row: 4/6;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 100%
  }

  .projectDiv{

    background-color: #389979;
    width: 6rem;
    position: relative;
    right: 1.8rem;
    bottom: 0.6rem;
    border:  #389979;
    border-style: solid;
  }
  
  .projectList{

    padding-top: 0.4rem;
    margin-left: 0.07rem;

  }

  a.navListItem{

    font-weight: 300 !important;
    color: white;
    border-bottom: solid;
    border-width: 0.1rem;

  }
  .listEle {

    font-size: 0.75rem !important;
    margin-bottom: 1.3rem;

  }

  .navListItem{

    font-weight: 500 !important;
  }

  .dropDown{

    width: 2.75rem;
    height: 2.75rem;
    position: relative;

  }
 
  #developmentMessage {
  
    font-size: 25px;
  
  }
  
  #pageBody {

    height: 100%;
    grid-column: 1/13;
    grid-row: 4/15;

  }

  #bodyGridContainer{

    display: grid;
    height: 100%;
    grid-template-columns: repeat(10, 1fr);
    grid-template-rows: 3rem repeat(14, 1fr);

  }

  .bodyRow1{


    grid-row: 3/8;

  }

  .bodyRow2{

    grid-row: 9/14;
  }

  .bodyColumn1{
    grid-column: 2 / 5;

  }

  .bodyColumn2{
    grid-column: 7 / 10;
  }

  #careMessage {
  
    grid-row: 1 / 2;
    grid-column: 1 / 10;
    font-size: 1.35rem;
    font-weight: 800;
    text-align: left;
    position: relative;
    left: 1rem;
    top: 0.2rem;
    color: rgb(90, 88, 88);
  
  }

  .homepageOption{

    grid-column: 2 / 3;

  }
  


  .gridContainer {
  
    height: 100%;
    display: grid;
  
    grid-template-columns: repeat(18, 1fr);
    grid-template-rows: 8rem repeat(10, 1fr);

  }
  


    
  form {

  
    grid-column: 2 / span 16;
    border-style: solid;
    border-width: 1px;
    grid-row: 7/11;
    align-self: start;
    height: 100%;
  }
  

  .formHeader {
    top: 1rem;
    width: 100%;
    background-color: #389979;
    height: 20%;
    color: white;
    margin-bottom: 1vh;
    line-height: 220%;
    font-size: 1.5em;
    text-align: center;


  }

  /* Form row titles */
  h2 {
  
  
    font-size: 1.1em !important;
    font-weight: 400 !important;
  
  
  }

  .formSegment {
    margin-top: 0.5em;
  }
  
  .formSegmentContainer {
  
    margin-left: 0.3em;

    height: 90%;
  }

  input {
  
    width: 8em;
    height: 1.9em;
    border-width: 1.5px;
    border-style: solid;
    margin-left: 5px !important;
    margin-top: 3px !important;
  }

  
  #submitButton {
  
    width: 5.5em;
    height: 2.8em !important;
    border-radius: 0%;
    position: relative !important;
    left: 74% !important;
    bottom: 3.5vh;
    text-align: center;
    
  }
  
  
  .image {
  
    width: 7rem;
    height: 7rem;
    margin-left: auto;
    margin-right: auto;
    display: block;
    margin-bottom: 1vw;
  
    border-radius: 100%;
  
  }
  
  .column {
  
    justify-content: center;
    align-content: center;
  
  }
  
  
  #zip {
  
    width: 5.5em;
  }
  
  
  
  /* Page titles */
  h3 {
  
    font-size: 1rem !important;
    font-weight: 700 !important;
  
  }

 
  
  
  .careObjectives {
  
    padding-bottom: 1vh;
  
    font-size: 1em;
  
    grid-column: 2 / 16;
    grid-row: 2 / 9;
  }
  
  .careObjectivesList {
  
    margin-left: 7vw;
    margin-top: 1.3vh;
    display: list-item;
    list-style-type: square;
  }
  
  .careObjectivesList>li {
  
    margin-top: 2vh;
  
  }
  
  #loginComponent {
  
  
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
  
  }

  #formTable {
  
  
    grid-column: 2 / 12;
  
    grid-row: 1 / 12;

    border-style: solid;
  

  
  }

  #formTable > tr, th, td{

    border: solid;
    
  }

  #formTable > thead > tr > th{

    background-color: #389979 !important;
    text-align: center;
    font-weight: 800;
    justify-self: center;

  }

  #formTable > tbody > tr > td{

    text-align: center;
  }

  button {
  
    width: 7.5rem;
    height: 4rem !important;
    font-weight: 800;
    border-radius: 40%;
  
  }

  .button {

    font-size: 0.9rem !important;
  }
 
  .contactInfoText{
    font-size: 0.9rem;
    padding-right: 0.25rem;
    position: relative;
    font-weight: 700;
    color: white;
  
  }

  #contactInfoHome {
  
    grid-column: 5/13;
    align-self: start;
    text-align: right;
    position: relative;
    right: 0.2rem;

  
  
  }
  

}


/* For landscape mode, change the form layout, and move the dropdown in the page header */
/* Extra Small Devices, Phones */
@media only screen and (min-width : 450px) and (max-width : 1100px) {


  form {

  
    grid-column: 2 / span 11;
    border-style: solid;
    border-width: 1px;
    grid-row: 7/12;
    align-self: start;
    height: 100%;
    width: 29rem;
  }

  input {
  
  
    width: 9rem;
    height: 2rem;
    border-width: 1.5px;
    border-style: solid;
    margin-left: 5px !important;
    margin-top: 3px !important;
    font-size: larger;
  }

  #zip{

    width: 5rem;
  }

  #submitButton {
  
    width: 5.5em;
    height: 2.8em !important;
    border-radius: 0%;
    position: relative !important;
    left: 77% !important;
    bottom: 2rem;
    text-align: center;
    
  }

  
  /* Page titles */
  h3 {
  
    font-size: 1rem !important;
    font-weight: 700 !important;
  
  }

 
  
  
  .careObjectives {
  
    padding-bottom: 1vh;
  
    font-size: 1em;
  
    grid-column: 2 / 16;
    grid-row: 2 / 9;
  }
  
  .careObjectivesList {
  
    margin-left: 7vw;
    margin-top: 1.3vh;
    display: list-item;
    list-style-type: square;
  }
  
  .careObjectivesList>li {
  
    margin-top: 2vh;
  
  }

  #formTable {
  
  
    grid-column: 2 / 12;
  
    grid-row: 1 / 12;

    border-style: solid;
  

  
  }

  #formTable > tr, th, td{

    border: solid;
    
  }

  #formTable > thead > tr > th{

    background-color: #389979 !important;
    text-align: center;
    font-weight: 800;
    justify-self: center;

  }

  #formTable > tbody > tr > td{

    text-align: center;;
  }
  
  

}

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {}

/* Medium Devices, Desktops */
@media only screen and (min-width : 1101px) {

  * {
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
  }


  .formHeader {
    width: 100%;
    background-color: #389979;
    min-height: 9vh;
    display: flex;
    flex-direction: column;
    font-size: calc(10px + 2vmin);
    color: white;
    margin-bottom: 1vh;
  
    font-size: 2.5rem;
  
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  
  
  ul.nav {
  
  
    grid-column: 3 / 12;
    justify-self: center;
    align-self: end;
  
  }
  
  ul.nav>li {
    display: inline-block;
    padding: 10px 20px;
    font-size: 20px;
  }
  
  ul.nav>li:hover {
    background-color: #8f2525;
  }
  
  ul.nav>li>a {
    color: white;
    text-decoration: none;
  }
  
  
  .App-link {
    color: #61dafb;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
  
    to {
      transform: rotate(360deg);
    }
  }
  

  
  
  
  
  #header {
    text-align: left;
    align-content: left;
    margin-left: 5vw;
    margin-top: 1vw;
  }
  
  
  .App-header {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: #800020;
  
    height: 14rem;
  
    display: grid;
  
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(1, 1fr);
  
  
    color: white;
  }
  
  .pageHeader {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: #800020;
  
    height: 7.3rem;
  
    display: grid;
  
    grid-template-columns: repeat(13, 1fr);
  
  
    color: white;
  }

  .contactInfoText{
    font-size: 2.5rem;
    grid-column: 11/13;
    grid-row: 1/2;
    padding-right: 0.75rem;
    position: relative;
    top: 6.5rem;
    color: white;
    text-align: right;
  }
  
  #contactInfoHome {
  
    font-size: 1.3rem;
    grid-column: 12/13;
    grid-row: 1/2;
    align-self: start;
    text-align: right;
  
  
  }
  
  .contactInfoPage {
  
    font-size: 1.3rem;
    grid-column: 11/14;
    grid-row: 1/2;
    padding-right: 1.1rem;
    text-align: right;
  
  }
  
  
  #homepageHeaderMessage {
  
  
    grid-column: 3/12;
    grid-row: 1/3;
    font-size: 2.9rem;
    justify-self: center;
    align-self: center;
    position: relative;
  
  
  }
  
  #aboutMe {
    font-size: 1.5rem;
  
    grid-column: 12 / 13;
    grid-row: 1/2;
    text-align: right;
    padding-right: 1.1rem;
    padding-bottom: 0.2rem;
  
    align-self: end;
    position: relative;
  
  }
  
  
  #homepageLogo {
  
    position: absolute;
    left: 1rem;
    grid-column: 1 / 5;
    grid-row: 1/2;
    width: 35rem;
    height: 28rem;
    align-self: center;
    
  
  }
  
  .pageLogo {
  
    grid-column: 1 / 4;
    grid-row: 1/ 2;
    align-self: center;
    height: 15rem;
    width: 15rem;
    position: absolute;
    bottom: -6.7rem;
    left: 0.2rem;
  
  }
  
  
  
  #emphasizedEnjoy {
  
    font-size: 60px;
    font-weight: 800;
  }
  
  #developmentMessage {
  
    font-size: 25px;
  
  }


  #bodyGridContainer{

    display: grid;
    height: 100%;
    grid-template-columns: repeat(10, 1fr);
    grid-template-rows: repeat(8, 1fr);

  }

  .bodyRow1{


    grid-row: 5/10;

  }

  .bodyRow2{

    grid-row: 5/10;

  }



  .bodyColumn1Landscape{
    grid-column: 2 /4;
  }

  .bodyColumn2Landscape{
    grid-column: 4 /6;
  }
  .bodyColumn3Landscape{
    grid-column: 6 / 8;
  }
  .bodyColumn4Landscape{
    grid-column: 8 / 10;
  }

  #careMessage {
  
    grid-column: 2/5;
    grid-row: 4 / 6;
    font-size: 40px;
    margin-bottom: 3rem;
    font-weight: 800;
    text-align: left;
    color: rgb(90, 88, 88);
  
  }
  
  
  button {
  
    width: 230px;
    height: 120px !important;
    font-weight: 800;
    border-radius: 40%;
  
  }
  
  .button {
  
    font-size: 1.75rem !important;
  }

  
  #submitButton {
  
    width: 70px;
    height: 40px;
    border-radius: 0%;
    position: relative !important;
  
    float: right;
    position: relative;
    bottom: 0.8vh;
    right: 0.6vw;
  
  }

  .gridContainer {
  

    display: grid;
    width: 120rem;
    height: 70rem;
    z-index: -5;
  
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: 12rem repeat(16, 1fr);
    position: absolute;

    left: 50%;
    margin-right: -50%;
    transform: translate(-50%);
  
  }

  .gridContainerManagement {
  

    display: grid;
    width: 90%;

    z-index: -5;
  
    grid-template-columns: repeat(12, 1fr);

    position: absolute;

    left: 50%;
    margin-right: -50%;
    transform: translate(-50%);
  
  }
  
  
  form {
  
    grid-column: 3 / span 8;

    width: 40rem;
    grid-row: 9/16;
    border-style: solid;
    border-width: 1px;
    align-self: start;
  }
  
  .formSegment {
    margin-top: 1.5vh;
  }
  
  .formSegmentContainer {
  
    margin-left: 1vw;
  }
  
  .image {
  
    width: 18rem;
    height: 18rem;
    margin-left: auto;
    margin-right: auto;
    display: block;
    margin-bottom: 1vw;
  
    border-radius: 100%;
  
  }
  
  .column {
  
    justify-content: center;
    align-content: center;
  
  }
  
  input {
  
  
    width: 12rem;
    height: 2rem;
    border-width: 1.5px;
    border-style: solid;
    margin-left: 5px !important;
    margin-top: 3px !important;
    font-size: larger;
  }
  
  #zip {
  
    width: 5vw;
  }
  
  
  /* Form row titles */
  h2 {
  
  
    font-size: 1.5rem !important;
    font-weight: 400 !important;
  
  
  }
  
  /* Page titles */
  h3 {
  
    font-size: 1.9rem !important;
    font-weight: 700 !important;
  
  }
  
  
  .careObjectives {
  
    padding-bottom: 5vh;
  
    font-size: 1.5rem;
  
    grid-column: 3 / 13;
    grid-row: 2 / 9;
  }
  
  .careObjectivesList {
  
    margin-left: 3rem;
    margin-top: 1.3vh;
    display: list-item;
    list-style-type: square;
  }
  
  .careObjectivesList>li {
  
    margin-top: 2vh;
  
  }
  
  #loginComponent {
  
  
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
  
  }
  
  #formTable {
  
  
    grid-column: 2 / 12;
  
    grid-row: 1 / 12;

    border-style: solid;
  

  
  }

  #formTable > tr, th, td{

    border: solid;
    
  }

  #formTable > thead > tr > th{

    background-color: #389979 !important;
    text-align: center;
    height: 4rem;
    font-size: large;
    font-weight: 800;
    justify-self: center;

  }

  #formTable > tbody > tr > td{

    text-align: center;
    font-size: large;
    height: 3rem;

  }

  .projectContainer{

    display: none;
  }
  
  #formError{

    position: relative;

    left: 42rem;
    top: 0.5rem;
    font-size: large;
  }

  .pageImage {

    grid-column: 8 / 13;

    grid-row: 4 / 12;

    width: 37rem;
    height: 25rem;


    border-radius: 20%;

  }

 .pageDiv{

  z-index: -5;
 }

 #petCarePagePictureTwo{

  grid-column: 1/4;
  grid-row: 9/11;
  width: 15rem;
  height: 10rem;
  background-color: #389979;
  justify-self: center;
 
 }

 .formSortButton{

  width: 0.5rem;
  height: 1rem;
 }
 

}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {}